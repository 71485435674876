const firebaseConfigNewDev = {
  apiKey: "AIzaSyBUmtANkLfbFIrRLg8fAV_FhiwQCmZzVgM",
  authDomain: "ezbyte-admin-panel-new-dev.firebaseapp.com",
  projectId: "ezbyte-admin-panel-new-dev",
  storageBucket: "ezbyte-admin-panel-new-dev.firebasestorage.app",
  messagingSenderId: "15437871827",
  appId: "1:15437871827:web:04782b1307364d7b663226",
};

export default firebaseConfigNewDev;
